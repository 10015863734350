.clipboard {
    display: inline-block;
    /*
        Width of the content changes after copy, leading to
        layout threshing. Reserving space prevents that
    */
    min-width: 80px;
}

.feedback, button {
    font-weight: normal;
    font-size: 12px;
    padding: 1px 2px 1px 2px;
}

.feedback {
    color: #888;
    white-space: nowrap;
    padding-left: 0;
}

.button {
    margin: 0;

    color: #888;
    border-radius: 3px;
    transition: background-color 100ms linear;
}

.button:hover {
    color: #444;
    background-color: #E6E6E6;
}