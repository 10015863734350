.panel {
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 2px -1px #DDD;

    padding: 12px;
    background-color: #fff;
}

.table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
}

.header {
    border-bottom: 2px solid #F0F0F0;
    padding: 0;
    padding-right: 8px;
    white-space: nowrap;
    font-weight: bold;
}

.cell {
    border-bottom: 1px solid #F0F0F0;
    line-height: 2em;
    padding: 0;
    padding-right: 8px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.cellDataset {
    width: 100%;
    max-width: 0;
}
