/* XXX: this doesn't seem like anything that needs to be used in multiple places */
.centerContent {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.page {
    color: #333;

    position: relative;
}
