.header {
    max-width: 1200px;
    display: flex;
    align-items: center;
}

.name {
    flex: 1;
    font-size: 21px;
}

.source {
    color: #CFC8C8;
    font-size: 17px;
    font-weight: 320;
    font-style: italic;
}

.logos {
    display: flex;
    padding: 8px 0;
    padding-left: 1rem;
}

.uniteLogo {
    height: 40px;
}

.plutofLogo {
    height: 40px;
    padding-top: 4px;
    padding-bottom: 4px;
}

.logoSeparator {
    width: 1px;
    background-color: #CFC8C8;
    margin: 0 1rem 0 0.5rem;
}
