
.levelHeader {
    display: flex;
    margin-bottom: 1rem;
    justify-content: space-between;
}

.headerControls {
    display: flex;
    gap: .66em;
}

.sourceType {
    color: #f5b800;
    font-style: italic;
    margin-left: 0.25em;
}