.panel {
    margin-bottom: 1rem;
    box-shadow: 0 2px 2px -1px #ddd;
    border: 1px solid #ddd;
    border-radius: 4px;

    background-color: white;
}

.header {
    padding: 8px 8px 6px;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.1;
    border-bottom: 1px solid #ddd;
}

.contentContainer {
    padding: 0 5px;
}

.content {
    padding: 0;
}

.section {
    padding: 5px 5px 5px 8px;
}

.label {
    display: block;
    font-weight: 700;
}

.tableContent {
    padding: 5px 5px 5px 0px;
}

.tableSection td {
    vertical-align: top;
    padding: 0.25rem;
}

.tableSection td:first-child {
    padding-left: 0;
}