.table {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 4px;
    border-collapse: collapse;
    border-spacing: 0;
    background-color: white;
}

.columnHeader {
    border-bottom: 2px solid #ddd;
    padding: 8px;
    padding-left: 12px;
    line-height: 1.42857143;
    vertical-align: bottom;
    font-weight: 700;
}

.cell {
    border-top: 1px solid #ddd;
    padding: 8px;
    padding-left: 12px;
    line-height: 1.42857143;
    vertical-align: top;
}

.fieldLabel {
    font-weight: 500;
}

.list {
    margin: 0;
    padding: 0;
    padding-left: 1rem;
}
