.table {
    width: calc(min(100%, 400px));
    margin-bottom: 0.5rem;
}

.header {
    text-align: left;
    padding-left: 0;
}

.cell {
    padding-left: 0;
}

.header {
    margin: 0;
    margin-bottom: 0.5rem;
}