.alignment__top-level-container {
    display: flex;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.alignment__horizontal-container {
    width: 100%;
    height: 100%;
    overflow-x: auto;
    padding-bottom: 20px;
}

.alignment__horizontal-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.alignment__navigation {
    display: flex;
    position: relative;
}

.alignment__guideline {
    position: absolute;
    top: 0;
}

.alignment__vertical-container {
    flex: 1;
    overflow-y: auto;
}

.alignment__vertical-content {
    display: flex;
    position: relative;
    overflow: hidden;
}

.alignment__sticky-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
}

.alignment__sticky-vertical-container {
    /* Not auto, because scrollbar midtable is ghastly */
    overflow-y: hidden;
    margin-bottom: 20px;
}

.alignment__table {
    flex-shrink: 0;
    flex-grow: 0;

    border-collapse: collapse;
    border-spacing: 0;
    background-color: transparent;
    box-sizing: border-box;

    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
}

.alignment__table-row {
    border-bottom: none;
    height: 17px;
    cursor: pointer;
}

.alignment__table-row--hover {
    background-color: #ddd;
}

.alignment__table-row--selected {
    background-color: #eee;
}

.alignment__table-header {
    padding: 0 8px 0 0;
    white-space: nowrap;
    height: 25px;
    font-weight: bold;
    text-align: left;
}

.alignment__table-header--threshold {
    transform: rotate(-90deg);
    padding: 0;
    max-width: 12px;
}


.alignment__table-header--disabled {
    text-decoration: line-through;
    opacity: 0.5;
}
.alignment__table-cell {
    line-height: 1.4em;
    white-space: nowrap;
    padding: 0 8px 0 0;
}

.alignment__table-cell--cluster-link {
    width: 12px;
    border: 1px solid white;
    background-color: white;
    padding: 0;
    cursor: pointer;
}

.alignment__table-cluster-link {
    display: block;
    width: 100%;
    height: 15px;
}

.alignment__table-cell--sequence {
    font-family: monospace;
}

.alignment__alignment-canvas {
    overflow: hidden;
    flex-shrink: 0;
    position: absolute;
    top: 0;
    cursor: pointer;
}