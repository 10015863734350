.container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    z-index: 1000;
}

.background {
    height: 100%;
    background-color: black;
    opacity: 0.5;
}

.content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.dialog {
    display: flex;
    flex-direction: column;
    max-height: 90vh;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3em;
    background-color: white;
}

.dialogHeader {
    display: flex;
    border-bottom: 1px solid #e9ecef;
    padding: 0.5rem;
    font-size: 14px;
    font-weight: bold;
}

.dialogBody {
    flex: 1 1 auto;
    padding: 0.5rem;
    overflow: auto;
    font-size: 14px;
}

.dialogFooter {
    padding: 0.5rem;
    text-align: right;
}