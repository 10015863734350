:root {
    --color-link: #337ab7;
}

* {
    box-sizing: border-box;
}

body {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 13px;
    line-height: 1.45;
    margin: 0;
    /* PlutoF background color. */
    background-color: #f0f0f0;
}

a {
    color: var(--color-link);
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

table {
    font-size: inherit;
}

/* TODO: Move to where it's used (and no need for BEM) */
.top-bar {
    display: inline-block;
    position: relative;
    width: 100%;
    background-color: #202020;
    padding: 10px;
    height: 2.6rem;
    z-index: 102;
    box-shadow: #202020 0 0 4px;
    margin-bottom: 20px;
}

.top-bar__brand {
    display: inline-block;
    height: 100%;
    cursor: pointer;
}

.top-bar__brand__logo {
    height: 100%;
}

.top-bar-button-bar-wrapper {
    float: right;
    display: flex;
    line-height: inherit;
    box-sizing: border-box;
}

.login-form-button {
    border-radius: 2px;
    border: 1px solid #609061;
    font-weight: 600;
    color: #333;
    background-color: #98CC99;
    margin: 0 4px;
    font-size: 12px;
    padding: 1px 4px;
}