.panel {
    padding: 10px 4px;
}

.gallery {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
    margin-top: -5px;
    margin-bottom: -5px;
}

.verticalGallery {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-bottom: -8px;
}

.item {
    padding: 5px 5px 5px 4px;
}

.verticalItem {
    margin-bottom: 8px;
}

.thumbnail {
    display: block;
    width: 64px;
    height: 64px;
    border-radius: 3px;
}
