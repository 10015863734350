.section {
    margin-bottom: 1rem;
}

.header {
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 21px;
    color: #333;
    line-height: 1.45;
}

.expanded {
    left: 0;
    max-width: calc(100vw - 2rem) !important;
}

.headerControls {
    display: inline-block;
    margin-left: .5rem;
}
