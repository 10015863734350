.navbar {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
}

.title {
    flex: 1;
    position: relative;
    top: 1px;
    margin-right: 12px;
    font-size: 20px;
}

.icon {
    margin-right: 0.5rem;
}

.search {
    margin-right: 0.5rem;
}

.pagination {
    margin-left: auto;
}

.navbarButton {
    display: inline-block;

    margin: 0;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 5px 10px;

    color: #333;
    background-color: #fff;
    font-size: 12px;
    line-height: 1.5;
    font-weight: bold;
    text-align: center;
    white-space: nowrap;
    box-shadow: none;
    cursor: pointer;
}

.navbarButton:enabled:hover {
    background-color: #e6e6e6;
    border-color: #adadad;
}

.navbarButton:disabled {
    opacity: 0.65;
    cursor: not-allowed;
}