.selector {
    display: flex;
    border-bottom: 1px solid #ddd;
}

.nav {
    margin-bottom: -1px;
    margin-right: 4px;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;

    padding: 10px 15px;
    line-height: 1.6rem;
    color: var(--color-link);
    cursor: pointer;
}

.nav:not(.navActive):hover {
    border-color: #eee;
    background-color: #eee;
}

.navActive {
    color: #555;
    background-color: white;
    border: 1px solid #ddd;
    border-bottom-color: transparent;
    cursor: default;
}

.content {
    border: 1px solid #ddd;
    border-top: none;
    border-radius: 0 0 4px 4px;

    padding: 12px;
    background-color: white;
}

.tabContent {
    display: none;
}

.tabContentActive {
    display: initial;
}
