.image {
    max-width: 94vw;
    max-height: 77vh;
}

.footer {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.footerInfo {
    display: flex;
    justify-content: space-between;
    gap: .66em;
}

.header {
    flex: 1;
    display: flex;
    justify-content: space-between;
}

.headerControls {
    display: flex;
    gap: .66em;
    font-size: 16px;
}

.headerControl {
    font-size: 16px;
    margin-top: auto;
    margin-bottom: auto;
}

.closeButton {
    font-size: 20px;
}

.table td {
    text-align: left;
}

.table td:last-child {
    text-align: right;
}

.modalNavBtn {
    position: absolute;
    top: 50%;
    font-size: 3rem;
    opacity: .66;
    transform: translateY(-50%);
    color: #fff;
}

.modalNavBtn:hover {
    background-color: inherit;
    opacity: .8;
    color: #fff;
}

.navBtnNext {
    right: 1rem;
}

.navBtnPrev {
    left: 1rem;
}
