.search {
    display: flex;
    height: 100%;
}

.queryInput {
    border: 1px solid #ccc;
    border-right: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    padding: 0.25rem 0.5rem;
}

.searchButton {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
