.button {
    display: inline-block;
    height: 24px;
    margin: 0;
    border: #cacbcc 1px solid;
    border-radius: 0;

    padding: 0 8px;
    background-color: transparent;
    color: #3a3e42;
    font-family: inherit;
    font-size: 14px;
    font-weight: 400;
    line-height: 1em;
    white-space: nowrap;
    cursor: pointer;
}

.button + .button {
    margin-left: 0.5em;
}

button:hover, button:active {
    border-color: #8b8d8e;
    color: #3a3e42;
    background-color: #fff;
}

button[disabled] {
    cursor: not-allowed;
    opacity: 0.4;
}

.linkButton {
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    color: var(--color-link);
    cursor: pointer;
}
