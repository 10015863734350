.overview {
    display: flex;
}

.overviewColumn {
    flex: 1;

    padding-right: 1rem;
}

.overviewColumn:last-child {
    padding-right: 0;
}
