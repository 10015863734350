/* Copied verbatim from plutof */
.navbar {
    display: flex;
    width: 100%;
    min-height: 56px;
    border: 1px solid #DDD;
    border-radius: 6px;
    margin-bottom: 20px;
    box-shadow: 0 2px 2px -1px #DDD;
    align-items: center;
    flex-wrap: wrap;
    padding: 8px 14px;
    background-color: #F8C232;
}

.navbarContent {
    flex: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}

.navbarTitleSection {
    position: relative;
    font-size: 20px;
    line-height: 30px;
    margin-left: 2px;
    margin-right: 12px;
}

.navbarTitle {
    margin-left: 0.25rem;
}

.navbarTitlePlutof {
    margin-left: 0.25rem;
}

.navbarActionsSection {
    justify-self: end;
}

.navbarAction {
    background-color: transparent;
    margin: 4px;
    border: 0;
    padding: 0 4px;
    font-size: 15px;
    line-height: 30px;
    box-shadow: none;
    cursor: pointer;
}

.navbarAction:hover, .navbarAction:focus {
    background-color: transparent;
    opacity: 0.9;
}

.navbarActionLabel {
    margin-left: 0.25em;
}