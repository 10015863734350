.files {
    margin: 0;
    padding-left: 0;
    list-style: none;
}

.file {
    margin-bottom: -1px;
    border: 1px solid #ddd;
    padding: 12px;

    &:first-child {
        border-radius: 4px 4px 0 0;
    }

    &:last-child {
        border-radius: 0 0 4px 4px;
    }
}

.field {
    margin: 0;
}

.label {
    display: block;
    font-weight: 700;
}

.tosCheckbox {
    margin: 4px 4px 0 0 !important; /* Clashing with boostrap when embedded */
}

.reasonDropdown {
    margin-bottom: 0.5em;
}