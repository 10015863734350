.pagination {
    display: inline-block;
}

.button {
    margin-right: 4px;
    line-height: 1;
}

.button:last-child {
    margin-right: 0;
}

.separator {
    display: inline-block;
    width: 1.5em;
}
