.panel {
    margin-bottom: 1rem;
    box-shadow: 0 2px 2px -1px #ddd;
    border: 1px solid #ddd;
    border-radius: 4px;

    padding: 0 5px;
    background-color: white;
}

.header {
    margin: 8px 6px 6px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.1;
}

.table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

.tableCell {
    border-top: 1px solid #ddd;
    padding: 5px 5px 5px 15px;
    vertical-align: top;
}

.label {
    display: block;
    font-weight: 700;
}
