.textInput {
    width: 100%;
    border-style: solid;
    border-width: 1px;
    padding: 0.25em;
    font-family: inherit;
}

.textInput_invalid {
    border-color: red;
}

.dropdown {
    width: 100%;
    border-style: solid;
    border-width: 1px;
    padding: 0.25em;
    font-family: inherit;
}

.dropdown_invalid {
    border-color: red;
}