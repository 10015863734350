.buttonReset {
    border: none;
    padding: 0;
    margin: none;
    outline: none;
    cursor: pointer;
    background-color: transparent;
}

.linkButton {
    display: inline-block;
    color: var(--color-link);
}
