.footer {
    margin: 1rem 0;
    text-align: right;
}

.footerLogo {
    height: 45px;
}

.rightColumn {
    display: flex;
}

.map {
    flex: 1;
}